import { render, staticRenderFns } from "./SectionContact.vue?vue&type=template&id=2332b72e&scoped=true&fucntional=true&"
var script = {}
import style0 from "./SectionContact.vue?vue&type=style&index=0&id=2332b72e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2332b72e",
  null
  
)

export default component.exports
<template functional>
  <article class="portfolio-item">
        <div class="portfolio-item__image">
           <a :aria-label="'Go to' + props.item.title" :href="props.item.link" target="_blank"><img :src="props.item.img" :alt="props.item.title" /></a>
        </div>
        <div class="portfolio-item__content" v-if="props.item.title">
            <header class="portfolio-item__header">
                <h3>{{ props.item.title }}</h3>
                <a class="portfolio-item__header-link" :href="props.item.link" target="_blank">{{props.item.linkLabel ? props.item.linkLabel : props.item.link }} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg></a>
            </header>
            <p>{{ props.item.description }}</p>
            <ul class="portfolio-item__details">
                <li class="portfolio-item__detail"><strong>Responsibilities:</strong>
                    <ul>
                        <li v-for="responsibility in props.item.responsibilities" :key="responsibility">{{ responsibility}}</li>
                    </ul>
                </li>
                <li class="portfolio-item__detail"><strong>Tech:</strong>
                    <ul>
                        <li v-for="tech in props.item.techs" :key="tech">{{ tech }}</li>
                    </ul>
                </li>
                <li class="portfolio-item__detail"><strong>Supported By:</strong>
                    <ul>
                        <li v-for="support in props.item.supports" :key="support">{{support}}</li>
                    </ul>
                </li>
            </ul>
        </div>
  </article>
</template>

<script>
export default {
  name: "PortfolioElement",
  props:{
      item:{
          type:Object,
          default:() => {}
      }
  }
};
</script>


<style scoped lang="scss">
.portfolio-item{
    width:100%;
    padding:0;
    margin:60px 0;
    &__image{
        img{
          display:inline-block;
        }
        flex:0 0 auto;
        margin-bottom:24px;
    }
    &__content{
        flex:1 1 auto;
      
    }
    &__header{
        
        h3{
            font-family: 'Playfair Display', serif;
            margin-right:4px;
        }
    }
    &__header-link{
        color:#BC4A24;
        display:block;
        padding:4px 0;
        transition:0.2s;
        font-size:0.875rem;
        svg{
            height:0.75rem;
        }
        &:hover{
            background:#DB6943;
            color:#222222;
        }
    }
    &__details{
        list-style:none;
        padding:0;
        margin:0;
        color:#6e6e6e;
        margin-top:24px;
       
    }
    &__detail{
        width:100%;
        margin:12px 0 0 24px;
        flex:0 1 auto;
        font-size:0.825rem;
    }
    @media all and (min-width:768px){
        .portfolio-item{
            padding:0 24px;
             margin:96px 0;
            &__details{
                display:flex;
            }
            &__detail{
                width:33%;
                //font-size:1rem;
                margin:12px 0 0;
            }
            &__header{
                display:flex;
        flex-wrap: wrap;
        align-items:center;
            }
            &__header-link{
                padding:4px 8px;
            }
        }
    }
    @media all and (min-width:1320px){
        width:90%;
        padding:0;
        display:flex;
        .portfolio-item{
            &__image{
                width:320px;
                margin-bottom:0;
            }
            &__content{
                  margin-left:24px;
            }
        }
    }
    @media all and (min-width:1441px){
        width:70%;
        .portfolio-item{
            &__image{
                width:400px;
            }
        }
    }
}

</style>

<template>
  <nav class="n-navigation">
      <h2 class="accessible-hide">Main Navigation</h2>
    <a aria-label="Go to top" @click="scroll_to('home')" >
    <svg id="n-navigation__logo" class="n-navigation__logo" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 257.576 114.546" enable-background="new 0 0 257.576 114.546" xml:space="preserve">
        <g>
            <path d="M255.032,111.607h-35.999V50.94l-5.962,5.023l-1.353,1.14l-28.687,24.17l-35.667-30.052v60.386h-0.333h-36l-72-60.667
                v60.667h-36v-91V2.941v0h36v0l72,59.889V20.607v-18h36.333l35.766,30.359l35.902-30.359h35.999v18V111.607z"></path>
        </g>
    </svg>
    </a>
    <ul class="n-navigation__items">
        <li class="n-navigation__item"><a @click="scroll_to('work')" >Work</a></li>
        <li class="n-navigation__item"><a @click="scroll_to('resume')">Resume</a></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "TheNavigation",
  methods:{
      scroll_to(section){
          document
            .getElementById(section)
            .scrollIntoView({ behavior: 'smooth' });
      }
  }
};
</script>


<style scoped lang="scss">
nav{
    //background:#2c3e50;
    background:#DB6943;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    z-index: 10;
}
ul{
    margin:0;
    list-style: none;
    display:flex;
    width:100%;
    justify-content:flex-end;
    padding:0 12px;
    li{
        a{
            color:#222222;
            display:block;
            line-height:3rem;
            font-size: 1rem;
            text-decoration: none;
            background:#DB6943;
            transition:0.2s;
            padding:0 12px;
            &:hover{
                background:#ecf0f1;
                cursor:pointer;
            }
        }
    }
}
.n-navigation{
    &__logo{
        position:absolute;
        height:1.5rem;
        top:.75rem;
        left:24px;
        opacity:1;
        fill:#803a22;
        //transition:opacity 0.1s;
    }
}

@media all and (min-width:768px){
    .n-navigation__logo{
        height:2.5rem;
        left:48px;
    }
    ul{
        padding:0 12px;
    }
   ul li a{
       font-size:1.25rem;
       line-height:4rem;
   }
}


</style>

<template >
  <div class="s-intro s-section" id="home">
    <h1>Nick Machia is<br/> a Software Developer</h1>
    <p>A front-end developer with full-stack experience and a proven history of creating, deploying, and maintaining successful projects.</p>
    <ul>
        <li><a aria-label="Contact Me" href="mailto:nick@nickmachia.com">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
            <span>nick@nickmachia.com</span>
        </a></li>
        <li><a href="https://www.linkedin.com/in/nickmachia/" aria-label="Connect on LinkedIn" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg>
        <span>/nickmachia</span>
        </a></li>
    </ul>
    <button id="scroll_nudge" class="s-intro__arrow" aria-label="Go to Work Section" @click="scroll_down">
       <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-down-circle"><circle cx="12" cy="12" r="10"></circle><polyline points="8 12 12 16 16 12"></polyline><line x1="12" y1="8" x2="12" y2="16"></line></svg></span>
    </button>
  </div>
</template>

<script>
export default {
    name:"SectionIntro",
    methods:{
        scroll_down(){
            document
                .getElementById('work')
                .scrollIntoView({ behavior: 'smooth' });

        },
        nudge_scroll(){
            setTimeout(()=>{
                if(document.documentElement.scrollTop <= 10){
                    let _button = document.getElementById('scroll_nudge');
                    _button.classList.add('is-visible');
                };
            }, 3000);
        }
    },
    mounted(){
        this.nudge_scroll();
    }
    
}
</script>

<style lang="scss">
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translate(-50%,0);}
	40% {transform: translate(-50%,-12px);}
	60% {transform: translate(-50%,-6px);}
}

</style>
<style scoped lang="scss">
.s-section{
    min-height:100vh;
    position:relative;
    z-index: 1;
     &::after{
        position:absolute;
        bottom:0;
        left:30%;
        width:40%;
        height:1px;
        content:'';
        background:rgba(0,0,0,0.1);
    }
}
.s-intro{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:center;
    padding:48px 24px;
}
.s-intro__arrow{
    -webkit-appearance: none;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    border-radius: 0px;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    position:absolute;
    bottom:60px;
    width:48px;
    cursor: pointer;
    height:48px;
    left:50%;
    transform:translateX(-50%);
    opacity:0;
    pointer-events: none;
    transition:opacity 0.6s;
    &.is-visible{
        opacity:1;
        pointer-events: all;
    }
    span{
        position:absolute;
        bottom:0;
        left:50%;
        transform:translateX(-50%);
        animation: bounce 2s ease infinite;
    }


}
h1{
    font-size:2.75rem;
    line-height:1.15;
}
p{
    margin:24px 0;
    max-width:90%;
}
ul{
    list-style: none;
    padding:0;
    display:flex;
    flex-direction:column;

    margin:24px 0 0;
    li{
        margin-bottom:24px;
    }
    a{
        display:flex;
        align-items:center;
        padding:0.5rem 1rem;
        border:solid 1px #222;
        text-decoration: none;
        color:#222;
        position:relative;
        transition:0.2s;
        span{
            margin-left:12px;
        }
        &::after{
            position:absolute;
            bottom:0;
            left:0;
            width:100%;
            height:4px;
            content:'';
            background:#222;
        }
        &:hover,  &:focus{
            background:#DB6943;
        }
    }
}
@media all and (min-width:768px){
    .s-intro{
        padding:48px;
    }
    h1{
        font-size:4rem;
    }
    p{
        max-width:70%;
    }
    ul{
        flex-direction:row;
        li{
             margin-right:24px;
        }

    }
}
@media all and (min-width:1240px){
    h1{
        font-size:6rem;
    }
    p{
        max-width:40%;
    }
}
</style>

<template>
  <section class="s-work s-section" id="work">
    <h2>Past Work</h2>
    <p><strong>Currently:</strong> I work across several frontend development teams to ensure the quality and evolution of internal applications at a company that builds strategies to grow and protect enterprise brands. Because of the nature of my current role, none of that work is highlighted in this porfolio. </p>
    <portfolio-element v-for="item in items" :key="item.title" :item="item"/>
  </section>
</template>

<script>
import PortfolioElement from '@/components/PortfolioElement';
export default {
  components: { PortfolioElement },
  name: "SectionWork",
  data:() => ({
      items:[ 
          {
              title:'ProRodeo.com',
              img:require('@/assets/rodeo.png'),
              link:'https://prorodeo.com',
              description:'Official website for the Professional Rodeo Cowboys Association',
              responsibilities:['Complete Front-end Architecture', 'Performance Audits', 'Contributed to API Services and Data-Modelling'],
              techs:['Vue, JavaScript, SASS/SCSS, HTML','Figma'],
              supports:['Design Team', 'Back-end Development Team']
          },{
              title:'RollTide.com',
              img:require('@/assets/bama.png'),
              link:'https://rolltide.com',
              description:'Official Athletics website for the University of Alabama',
              responsibilities:['Front-End Integration', 'Development of extended Live-Stats component'],
              techs:['Knockout.js, JavaScript, LESS, HTML','Avocode'],
              supports:['Design Team', 'Back-end Development Team']
          },{
              title:'LSU 2019/20 National Champions Microsite',
              img:require('@/assets/lsu.png'),
              link:'https://lsusports.net/cfp2020',
              linkLabel:'https://lsusports.net/cfp2020 (retired)',
              description:'Microsite takeover for the Official Athletics website for LSU',
              responsibilities:['Front-End Integration', 'Coordination with client\'s marketing team'],
              techs:['Vue, JavaScript, SASS/SCSS, HTML','Lottie', 'Avocode'],
              supports:['Design Team']
          },{
              title:'PastCast',
              img:require('@/assets/pastcast.png'),
              link:'https://optimistic-babbage-44ceb0.netlify.app/',
              description:'Personal side-project to experiment with combining API sources, Authentication, Charting Libraries, and Vue',
              responsibilities:['Full Development'],
              techs:['Vue, JavaScript, SASS/SCSS, HTML','Strava and DarkSky APIs'],
              supports:['Solo Project']
          }
      ]
  })
};
</script>


<style scoped lang="scss">
.s-section{
    min-height:100vh;
    position:relative;
    z-index: 1;
    &::after{
        position:absolute;
        bottom:0;
        left:30%;
        width:40%;
        height:1px;
        content:'';
        background:rgba(0,0,0,0.1);
    }
}
.s-work{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:flex-start;
    padding:96px 24px;
}
h2{
    font-size:3rem;
    line-height:1.15;
}
p{
    margin:24px 0;
    max-width:90%;
    text-align: left;
}
ul{
    list-style: none;
    padding:0;
    display:flex;
    margin:24px 0 0;
    li{
        margin-right:24px;
    }
    a{
        display:block;
        padding:0.5rem 1rem;
        border:solid 1px #fefefe;
        text-decoration: none;
        color:#fefefe;
        position:relative;
        transition:0.2s;
        &::after{
            position:absolute;
            bottom:0;
            left:0;
            width:100%;
            height:4px;
            content:'';
            background:#fefefe;
        }
        &:hover,  &:focus{
            background:#fefefe;
            color:#34495e;
        }
    }
}

@media all and (min-width:768px){
    .s-work{
        padding:96px 48px;
    }
    h2{
        font-size:4rem;
    }
    p{
        max-width:70%;
    }
}
@media all and (min-width:1300px){
    p{
        max-width:40%;
    }
}
</style>

<template>
  <section class="s-resume s-section" id="resume">
    <h2>Resume</h2>
    <div class="s-resume__body">
        <div class="s-resume__experience">
            <h3>Experience</h3>
            <ul class="s-resume__items">
                <li class="s-resume__item" v-for="item in experience" :key="item.title">
                    <div class="s-resume__item-company">{{item.company}}</div>
                    <h4>{{item.title}}</h4>
                    <div class="s-resume__item-time">{{ item.time}}</div>
                    <ul>
                        <li class="s-resume__item-bullet" v-for="bullet in item.bullets" :key="bullet">
                            {{ bullet }}
                            </li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="s-resume__skills">
            <h3>Skills</h3>
            <ul class="s-resume__skills-items">
                <li class="s-resume__skills-item">
                    <h4>Production-Level</h4>
                    <ul>
                        <li class="s-resume__item-bullet">Vue</li>
                        <li class="s-resume__item-bullet">Knockout</li>
                        <li class="s-resume__item-bullet">JavaScript</li>
                        <li class="s-resume__item-bullet">API Ingestion</li>
                        <li class="s-resume__item-bullet">SASS/SCSS/LESS</li>
                        <li class="s-resume__item-bullet">Jest</li>
                        <li class="s-resume__item-bullet">Responsive Design</li>
                        <li class="s-resume__item-bullet">Design Systems</li>
                        <li class="s-resume__item-bullet">Vuetify</li>
                        <li class="s-resume__item-bullet">Docker</li>
                        <li class="s-resume__item-bullet">Semantic HTML</li>
                        <li class="s-resume__item-bullet">GIT</li>
                        <li class="s-resume__item-bullet">Figma</li>
                        <li class="s-resume__item-bullet">Web Accessibility</li>
                        <li class="s-resume__item-bullet">Browser Compatibility</li>
                        <li class="s-resume__item-bullet">C#</li>
                        <li class="s-resume__item-bullet">ASP.NET</li>
                        <li class="s-resume__item-bullet">SQL</li>
                    </ul>
                </li>
                <li class="s-resume__skills-item">
                    <h4>Exposure</h4>
                    <ul>
                        <li class="s-resume__item-bullet">Web Components</li>
                        <li class="s-resume__item-bullet">Sketch</li>
                        <li class="s-resume__item-bullet">PHP</li>
                        <li class="s-resume__item-bullet">Python</li>
                        <li class="s-resume__item-bullet">WordPress</li>
                    </ul>
                </li>
            </ul>
                    <h3>Education</h3>
                    <div class="s-resume__education-school">Clarkson University, Potsdam, NY</div>
                    <h4>Bachelor of Science in Digital Arts and Sciences</h4>
                    <div class="s-resume__education-time">May 2009</div>
               
        </div>
    </div>

  </section>
</template>

<script>

export default {
  name: "SectionResume",
  data:() => ({
      experience:[
          {
              company:'Terakeet, Syracuse,NY',
              title:'Senior Frontend Software Developer',
              time:'April 2021 - Present',
              bullets:[
                  'Lead developer on the creation and adoption of a unified Design System for internal applications',
'Interview, onboard, and mentor frontend developers of all levels',
'Review over 250 pull requests to ensure quality of code',
'Establish best practices and work with Product teams to standardize frontend development across applications',
'Create CI/CD pipelines and automate tasks to improve developer experience',
'Write over 400 tests using Jest to increase frontend code coverage and reliability.', 
'Work with cross-functional teams to research feasibility and best path forward when spec\'ing new product solutions',
'Work with Product Managers and UI/UX designers to create technical specifications on over 125 product stories.',
              ]
          },
          {
              company:'SIDEARM Sports, Syracuse,NY',
              title:'Tech Lead (Senior) Frontend Developer',
              time:'December 2019 - April 2021',
              bullets:[
                  'Lead front-end development on multiple high-visibility, high-traffic projects through clean code, integration of design frameworks, and open communication with clients. Specifically, custom Vue.js based SPA frontends for a headless CMS as well as custom, in some cases fairly complex; admin backends to support frontend model complexity.',
                  'Work with a team of senior designers and developers to migrate our platform towards a Global Design System.',
                  'Make key contributions to a large (40+) reusable component library that is utilized by over 1,400 official athletics websites.',
                  'Carry out performance audits and optimize code for high-traffic events like March Madness (300,000+ users in a single day) and the National Finals Rodeo (5 million+ page views over 9 days).',
                  'Interview, onboard, and mentor front-end developers of all levels on a team that has grown from 6 to 15 developers.',
                  'Organize regular “lunch and learn” meetings, often presenting; focusing on industry trends, performance, VS Code productivity, and frameworks.',
                  'Continue to be a major contributor in establishing the team style guide and growing documentation content.',
              ]
          },{
              company:'SIDEARM Sports, Syracuse,NY',
              title:'Senior Frontend Developer',
              time:'April 2017 - December 2019',
              bullets:[
                  'Personally developed 97 new websites by collaborating with internal teams (design, PM, product) and clients, including some of the biggest brands in college athletics such as the University of Alabama, University of California (Berkley), and Penn State University.',
                  'Worked in conjunction with the design product team to create new features and update existing components of the SIDEARM platform.',
                  'Trained and onboarded new front-end developers on the team.',
                  'Developed, reviewed, and tested 188 bug fixes and feature requests.'
              ]
          }
      ]
  })
};
</script>


<style scoped lang="scss">
.s-section{
    min-height:100vh;
    position:relative;
    z-index: 1;
     &::after{
        position:absolute;
        bottom:0;
        left:30%;
        width:40%;
        height:1px;
        content:'';
        background:rgba(0,0,0,0.1);
    }
}
.s-resume{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:flex-start;
    padding:96px 24px;
    &__body{
        h3{
             font-family: 'Playfair Display', serif;
        }
    }
    &__experience, &__skills{
        margin:48px 0;
    }
    &__items, &__skills-items{
        list-style: none;
        margin:0;
        padding:0;
    }
    &__item, &__skills-item{
        margin:24px 0 60px;
        &-time, &-company{
            font-size:0.825rem;
        }
    }
    &__item-bullet{
        margin-bottom:8px;
    }
    &__education-school, &__education-time{
        font-size:0.825rem;
    }
    &__education-school{
        margin-top:24px;
    }
}
h2{
    font-size:3rem;
    line-height:1.15;
}
h3{
    margin-top:24px;
    font-size:2rem;
}
p{
    margin:24px 0;

    text-align: center;
}
ul{
    margin-top:12px;
}

@media all and (min-width:768px){
    .s-resume{
        padding:96px 48px;
        &__body{
            display:flex;
            align-items: flex-start;
            justify-content:space-between;
        }
        &__experience{
            margin-right:96px;
        }
    }
    h2{
          font-size:4rem;
    }
    h3{
        font-size:2.5rem;
    }
}
</style>

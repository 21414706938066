<template>
  <div id="app">
    <svg id="app__logo" class="app__logo" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 257.576 114.546" enable-background="new 0 0 257.576 114.546" xml:space="preserve">
        <g>
            <path d="M255.032,111.607h-35.999V50.94l-5.962,5.023l-1.353,1.14l-28.687,24.17l-35.667-30.052v60.386h-0.333h-36l-72-60.667
                v60.667h-36v-91V2.941v0h36v0l72,59.889V20.607v-18h36.333l35.766,30.359l35.902-30.359h35.999v18V111.607z"></path>
        </g>
    </svg>
    <section-intro/>
    <the-navigation />
    <section-work/>
    <section-resume/>
    <section-contact/>
  </div>
</template>

<script>
import TheNavigation from "@/components/TheNavigation";
import SectionIntro from "@/components/SectionIntro";
import SectionWork from "@/components/SectionWork";
import SectionResume from '@/components/SectionResume';
import SectionContact from '@/components/SectionContact';

let resize_timer = false;
let resize_throttled = false;

export default {
  name: "App",
  components: {
        TheNavigation,
        SectionIntro,
        SectionWork,
        SectionResume,
        SectionContact
  },
  methods:{
    throttled_scroll(){
        if(!resize_throttled){
            this.handle_fade();
            resize_throttled = true;
            setTimeout(() => {
                resize_throttled = false;
            },100);
        }


        clearTimeout(resize_timer);
        resize_timer = setTimeout(() => {
            this.handle_fade();         
        }, 101);
        
        
    },
    handle_fade(){
        let _logo = document.getElementById('app__logo');
        let _navlogo = document.getElementById('n-navigation__logo');

        let scale = 100/(window.innerHeight/2);
        let multiplier = (window.innerHeight/2) - document.documentElement.scrollTop;
        let value = (scale * multiplier) / 2000;

        let navmultiplier =(window.innerHeight/2) +  document.documentElement.scrollTop;
        let navvalue = ((scale * navmultiplier) / 200) - 0.5;

        _logo.style.opacity = value > 0 ? value : 0;
        _navlogo.style.opacity = navvalue > 1 ? 1 : navvalue;
        
    }
  }, 
  mounted(){
    window.addEventListener("scroll", this.throttled_scroll);
    this.throttled_scroll();
  }
};
</script>


<style lang="scss">

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}


body, html{
    //background:#34495e;
    background:#f2f3f4;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //color: #ecf0f1;
  color: #222;
}

h1,h2{
    font-family: 'Playfair Display', serif;
    font-weight:900;
}
.accessible-hide{
    display: block !important;
    position: absolute;
    left: -9999px;
    top: -9999px;
}
</style>
<style lang="scss" scoped>
.app{
    &__logo{
        position:fixed;
        height:70vh;
        bottom:0;
        right:0;
        margin-bottom:-5vh;
        margin-right:-5vw;
        opacity:0.05;
        fill:#bdbdbd;
        transition:opacity 0.2s;
    }
}
</style>
